<template>
  <v-col cols="12" md="6">
    <h3 class="reservationTitle" v-text="$ml.get('confirmation.resume')" />
    <v-row>
      <personal-data :reservation="reservation" :passengers="passengers" />
      <passenger-data :passengers="passengers" :seats="seats" :typus="typus"/>
      <contact-info/>
      <v-col cols="12" md="12">
        <p class="kidsAdvise">
          <span v-text="$ml.get('travel.form.kidsAdviseFirstPart')"/>
          <a href="tel:973750090">973750090</a>
          <span v-text="$ml.get('travel.form.kidsAdviseSecondPart')"/>
          <a href="mailto:kariba@autocarsgamon.com">kariba@autocarsgamon.com</a>
        </p>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import PersonalData from "./partials/personalData";
import PassengerData from "./partials/passengerData";
import ContactInfo from "../confirmation/contactInfo";

export default {
  name: "reservationInfo",
  components: {PassengerData, PersonalData,ContactInfo},
  props: ['typus','reservation','passengers', 'seats']
}
</script>

<style scoped>
.reservationTitle {
  color: #d81d86;
  font-weight: bold;
  font-size: 18pt;
}
.kidsAdvise {
  margin-top: 1em;
  border: 1px solid lightgray;
  padding: 1em;
  font-size: 14pt;
}

</style>