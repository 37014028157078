<template>
  <v-col cols="12" md="6" class="align-center">
    <h3 class="reservationTitle" v-text="$ml.get('confirmation.terms')" />
    <div style="display: flex">
      <v-col cols="12"  v-for="file in files" v-bind:key="file.fileID" >
        <v-card
            class="ma-5"
            outlined>
          <a :href="env_url+'/pdf/'+file.name" target="_blank" class="pdfLink">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline ">
                  <v-icon color="red" large>mdi-file-table-outline</v-icon>
                  {{ file.name }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </a>
        </v-card>
      </v-col>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "filesInfo",
  props:['files']
}
</script>

<style scoped>
.reservationTitle {
  color: #d81d86;
  font-weight: bold;
  font-size: 18pt;
}
</style>