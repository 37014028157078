<template>
  <v-col cols="12" md="12" class="align-center">
    <h4 class="reserTitle" v-text="$ml.get('confirmation.contact')" />
    <v-col cols="12" md="12" class="align-center contactInfo">
      <p>VIATGES KARIBA ESPECIALISTES SL – G.C. 3896</p>
      <p>  Camí de les Comes, 13 Pol. Ind. Torrefarrera (Lleida)</p>
      <p>  <a href="mailto:kariba@autocarsgamon.com">kariba@autocarsgamon.com</a></p>
      <p> <a href="tel:973750090">973 75 00 90</a> -  <a href="tel:687658688">687 65 86 88</a> - <a href="tel:696425940">696 42 59 40</a></p>
    </v-col>
  </v-col>
</template>

<script>
export default {
  name: "contactInfo"
}
</script>

<style scoped>
.contactInfo a {
  color: black;
  text-decoration: none;
}
p{
  font-size: 1rem;
}
 .reserTitle {
   font-weight: bold;
 }

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0.875rem;
  height: 1em;
}

td {
  padding: 1em !important;
}
th{
  font-weight: bold;
}
</style>