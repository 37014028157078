<template>
  <v-col cols="12" md="12">
    <h4 class="reserTitle" v-text="$ml.get('confirmation.passenger.title')"/>
    <v-simple-table dense class="mt-3">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" v-text="$ml.get('confirmation.passenger.name')"/>
          <th class="text-left" v-text="$ml.get('confirmation.passenger.nif')"/>
          <th class="text-left" v-text="$ml.get('confirmation.passenger.phone')"/>
          <th class="text-left" v-text="$ml.get('confirmation.passenger.seat')"/>
          <th class="text-left" v-if="typus==='2' || typus==='3'" v-text="$ml.get('confirmation.passenger.room')"/>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item,index in passengers"
            :key="item.dni">
          <td>{{ item.name }}</td>
          <td v-if="item.dni.length > 3">{{ item.dni }}</td>
          <td v-else>--</td>
          <td v-if="item.phone.length > 3">{{ item.phone }}</td>
          <td v-else>--</td>
          <td>{{ seats[index].seatID }}</td>
          <td v-if="typus==='2' || typus==='3'">{{ item.room }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-col>
</template>

<script>
export default {
  name: "passengerData",
  props: ['passengers', 'seats','typus']
}
</script>

<style scoped>
.reserTitle {
  font-weight: bold;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 1rem;
  height: 1em;
}

td {
  padding: 1em !important;
}
th{
  font-weight: bold;
}
</style>