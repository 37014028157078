<template>
  <v-col cols="12" md="10">
    <h4 class="reserTitle" v-text="$ml.get('confirmation.personalData.title')" />
    <v-simple-table id="personalTable">
      <template v-slot:default>
        <tbody>
        <tr>
          <td><b>{{$ml.get('confirmation.personalData.name')}}</b></td><td> {{reservation.name}}</td>
          <td><b>{{$ml.get('confirmation.personalData.phone')}}</b></td><td> {{passengers[0].phone}}</td>
        </tr>
        <tr>
          <td><b>{{$ml.get('confirmation.personalData.total')}}</b> </td><td>{{reservation.total}}€</td>
          <td><b>{{$ml.get('confirmation.personalData.mail')}}</b></td><td> {{reservation.email}}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-col>
</template>

<script>
export default {
  name: "personalData",
  props:['reservation','passengers']
}
</script>

<style scoped>
.reserTitle{
  font-weight: bold;
}
#personalTable b{
  font-weight: bold;
  font-size: 10pt;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 1rem;
  height: 1em;
}
td{
  padding: 1em !important;
}
</style>